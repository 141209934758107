<!-- https://github.com/matfantinel/fantinel.dev/blob/main/src/lib/components/atoms/Image.svelte -->
<script lang="ts">
	import { dev } from "$app/environment";
	import { HttpRegex } from "$lib/utils/externalLink";

	export let additionalClass: string | undefined = undefined;

	export let src: string;
	export let alt: string;
	export let figcaption: string | undefined = undefined;
	export let fullBleed: boolean | undefined = undefined;
	export let lazy: boolean = true;
	export let skipSrcset: boolean = false;

	export let formats: string[] = ["avif", "webp", "png"];
	export let widths: string[] | undefined = undefined;

	let fileName: string;
	let srcSet: string | undefined;

	$: {
		fileName = src ? src.split(".")[0] : "";
		srcSet = buildSrcset();
	}

	function buildSrcset() {
		if (dev || skipSrcset || HttpRegex.test(src)) return;

		// Only build srcset if files are png, jpg, jpeg, webp or avif
		if (!src.match(/\.(png|jpe?g|webp|avif)$/)) return;

		// If src is the cms/media folder, replace it with cms/optimized-media
		// "cms/media" must be in the beginning of the string (with or without a leading slash)
		src = src.replace(/^\/?cms\/media/, "/cms/optimized-media");
		fileName = fileName.replace(/^\/?cms\/media/, "/cms/optimized-media");

		let srcset = "";

		if (widths) {
			for (let i = 0; i < widths.length; i++) {
				srcset += `${fileName}-${widths[i]}w.${formats[0]} ${widths[i]}w`;

				if (i < widths.length - 1) {
					srcset += ", ";
				}
			}
		} else {
			for (let i = 0; i < formats.length; i++) {
				srcset += `${fileName}.${formats[i]}`;

				if (i < formats.length - 1) {
					srcset += ", ";
				}
			}
		}

		return srcset;
	}
</script>

{#if src}
	{#if figcaption}
		<figure class={additionalClass} class:full-bleed={fullBleed} {...$$restProps}>
			<img
				srcset={srcSet}
				{src}
				{alt}
				loading={lazy ? "lazy" : "eager"}
				decoding="async"
				class="rounded-3xl"
			/>
			<figcaption class="my-3 text-gray-500 text-sm text-center">{@html figcaption}</figcaption>
		</figure>
	{:else}
		<img
			srcset={srcSet}
			{src}
			{alt}
			loading={lazy ? "lazy" : "eager"}
			decoding="async"
			class="rounded-3xl {additionalClass}"
			class:full-bleed={fullBleed}
			{...$$restProps}
		/>
	{/if}
{/if}

<style>
	img,
	figure {
		width: full;
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.full-bleed {
		width: 100% !important;
	}
</style>
